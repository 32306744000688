.navbar {
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(5px);
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* Updated color scheme */
  background-color: rgba(3, 37, 65, 1); /* Dark blue-grey */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-header a {
  color: whitesmoke;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

.hamburger-menu {
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1rem;
  color: whitesmoke;
}

.dropdown-menu {
  display: flex;
  flex-direction: column; /* Changed to column for better mobile view */
  justify-content: space-around;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(3, 54, 73, 0.95); /* Lighter blue-grey for dropdown */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.dropdown-link {
  padding: 10px 20px;
  color: whitesmoke;
  text-decoration: none;
  background-color: rgba(3, 37, 65, 1); /* Match navbar for consistency */
  margin: 0.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.dropdown-link:hover {
  background-color: rgba(3, 54, 73, 1); /* Slightly lighter on hover */
  font-weight: 800;
  transition: all 0.1s ease;
}
