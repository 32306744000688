.contact-form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust height as needed */
  padding: 20px;
  background: linear-gradient(135deg, #021B79 0%, #0575E6 100%);
}

.contact-card {
  max-width: 500px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  padding: 30px;
  color: #FFFFFF;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.contact-card h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #EDEDED;
}

.contact-card p, .contact-card ul {
  margin-bottom: 15px;
  line-height: 1.5;
}

.contact-card ul {
  list-style: none;
  padding: 0;
}

.contact-card ul li {
  margin-bottom: 10px;
}
