.custom-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Center align the cards for better spacing */
    padding: 20px;
    background-color: rgb(26, 26, 26);


  }
  
  .custom-card {
    background-color: #ffffff; /* Ensuring text color contrasts well */
    border-radius: 15px; /* Slightly larger radius for a softer look */
    color: #333; /* Dark color for text to improve readability */
    padding: 20px;
    width: 100%;
    max-width: 300px; /* Max width to control card size */
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Updated shadow for subtlety */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .custom-card:hover {
    transform: translateY(-10px); /* Lift effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  }
  
  .card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: #fff; /* Ensuring icon color contrasts with the background */
    background-color: #007BFF; /* Default icon background, overridden inline */
    width: 100px; /* Increased size for visibility */
    height: 100px; /* Matching width for perfect circle */
    margin-bottom: 20px; /* Increased space between icon and title */
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); /* Subtle shadow for depth */
  }
  
  .custom-card h3, .custom-card p {
    color: whitesmoke ; /* Dark color for readability */
  }
  
  .custom-card h3 {
    font-size: 1.8rem; /* Larger font size for headings */
    margin-bottom: 15px;
  }
  
  .custom-card p {
    font-size: 1rem;
    line-height: 1.5; /* Improved line height for readability */
  }
  
  /* Adjustments for responsiveness */
  @media (max-width: 768px) {
    .custom-cards-container {
      justify-content: space-around;
    }
    .custom-card {
      width: calc(50% - 40px); /* Adjust width for tablet screens */
    }
  }
  
  @media (max-width: 480px) {
    .custom-card {
      width: 100%; /* Full width on mobile screens */
      margin: 0 10px; /* Add slight margin for breathing room */
    }
  }
  