/* Base styling for About Us section */
.aboutus-section {
  background-color: rgb(26, 26, 26);
  /* Light background to keep it clean and modern */
  padding: 50px 5%; /* Adequate padding for spacing */
  color: #333; /* Dark text for contrast and readability */
}

.aboutus-header {
  text-align: center;
  font-size: 2.5rem;
  color: white; /* A deep, professional blue */
  margin-bottom: 30px;
}

.aboutus-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px; /* Space between cards */
}

/* Styling for each card */
.card {
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Softened edges for a modern look */
  padding: 20px;
  transition: transform 0.3s ease-in-out; /* Smooth transform effect on hover */
}

.card:hover {
  transform: translateY(-5px); /* Slight raise effect on hover */
}

h3 {
  color: #007BFF; /* Consistent, vibrant color for headings */
  margin-bottom: 15px;
}

p {
  font-size: 1rem;
  line-height: 1.6; /* Enhanced readability */
}

/* Responsiveness */
@media (max-width: 768px) {
  .aboutus-header {
    font-size: 2rem; /* Adjusted for smaller screens */
  }

  .aboutus-content {
    grid-template-columns: 1fr; /* Stack cards on smaller screens */
  }

  .card {
    padding: 15px; /* Slightly reduce padding on smaller screens */
  }

  h3 {
    font-size: 1.5rem; /* Ensure headings are not too large on small screens */
  }

  p {
    font-size: 0.9rem; /* Slightly smaller text for small screens */
  }
}
