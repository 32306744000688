.hero {
  position: relative;
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover; /* Ensure video cover the whole section */
  z-index: -1; /* Ensure video stays in the background */
}

.hero-overlay {
  position: absolute;
  top: 0;
  height: calc(100vh);
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Maintain the overlay for text visibility */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: whitesmoke;
  text-align: center;
  font-size: 4rem; /* Adjusted for a more balanced appearance */
}
.hero-main-headline {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  z-index: 100;
}

.hero-subheadline {
  color: #f8f9fa;
  font-size: 1.5rem;
}

.hero-cta-group {
  display: flex;
  gap: 20px;
}

.primary-cta {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-cta:hover {
  background-color: #218f3c;
}

.secondary-cta {
  background: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.secondary-cta:hover {
  background-color: #6c757d;
}

.scroll-indicator {
  position: absolute;
  bottom: 10px;
  color: white;
  animation: bounce 1s infinite;
  font-size: 2rem;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .hero-main-headline {
    font-size: 2rem;
    text-align: center;
  }

  .hero-subheadline {
    font-size: 1.2rem;
    text-align: center;
  }

  .hero-content {

    
    font-size: 2rem;
  }


}
